"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REPORT_ISSUE_REASONS_TITLE = exports.PAYMENT_DELAY_REASONS_TITLE = exports.ESCALATION_REASON_CHOICES = exports.ESCALATION_REASON_MAPPING = exports.DELAY_REASON_MAPPING = void 0;
exports.DELAY_REASON_MAPPING = new Map([
    [0, { category: 0, label: 'Delayed salary' }],
    [10, { category: 0, label: 'Loss of employment' }],
    [18, { category: 0, label: 'Slow sales' }],
    [19, { category: 0, label: 'Expecting Funds' }],
    [1, { category: 0, label: 'Change of job' }],
    [11, { category: 0, label: 'No capacity to pay' }],
    [20, { category: 1, label: 'Delayed payment update' }],
    [21, { category: 1, label: 'Paid into QC commercial account' }],
    [22, { category: 1, label: 'Network/Bank app issue' }],
    [23, { category: 2, label: 'Did not receive loan' }],
    [24, { category: 2, label: 'Loan sent to an account user had requested to change' }],
    [25, { category: 2, label: 'Not aware of the installment loan' }],
    [26, { category: 3, label: 'Spoke to third-party, user unavailable' }],
    [27, { category: 3, label: 'Wrong Number' }],
    [28, { category: 3, label: 'New SIM claim' }],
    [16, { category: 3, label: 'Deceased' }],
    [8, { category: 4, label: 'Customer hung up' }],
    [29, { category: 4, label: 'Placed call on hold' }],
    [30, { category: 4, label: 'Dropped call' }],
    [31, { category: 4, label: 'Voicemail' }],
    [37, { category: 5, label: 'Poor connectivity' }],
    [38, { category: 5, label: 'Noisy environment' }],
    [32, { category: 6, label: 'Could not log into the app' }],
    [33, { category: 6, label: 'Incorrect details' }],
    [34, { category: 6, label: 'Waiting for OTP' }],
    [3, { category: 7, label: 'Ill health' }],
    [2, { category: 7, label: 'Childbirth' }],
    [35, { category: 8, label: 'Phone theft' }],
    [36, { category: 8, label: 'Phone damage' }],
]);
const PAYMENT_DELAY_REASON_CHOICES = Array.from(exports.DELAY_REASON_MAPPING.entries()).map(([key, { label }]) => ({
    value: key,
    label,
}));
exports.ESCALATION_REASON_MAPPING = new Map([
    [0, { category: 0, label: 'Informed' }],
    [1, { category: 0, label: 'Unaware' }],
    [2, { category: 0, label: 'Confused' }],
    [3, { category: 1, label: 'No intention to make the payment' }],
    [4, { category: 1, label: 'Silent' }],
    [5, { category: 1, label: 'Third-Party Response' }],
    [6, { category: 2, label: 'Impersonation' }],
    [7, { category: 2, label: 'Fake Income' }],
    [8, { category: 2, label: 'Third-Party Account details' }],
    [9, { category: 2, label: 'Untrusted Email Address' }],
]);
exports.ESCALATION_REASON_CHOICES = Array.from(exports.ESCALATION_REASON_MAPPING.entries()).map(([key, { label }]) => ({
    value: key,
    label,
}));
exports.PAYMENT_DELAY_REASONS_TITLE = 'What is the reason for the payment delay?';
exports.REPORT_ISSUE_REASONS_TITLE = 'Choice Escalation reasons';
exports.default = PAYMENT_DELAY_REASON_CHOICES;
