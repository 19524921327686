"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDER_BY_CHOICES = exports.SCHEDULE_STATUSES_CHOICES = void 0;
const schedulesConstants_1 = require("../../features/schedules/schedulesConstants");
exports.SCHEDULE_STATUSES_CHOICES = [
    { value: '', label: 'Select status' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_NOT_STARTED, label: 'Not Started' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_STARTED, label: 'Started' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_COMPLETED, label: 'Completed' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_RESCHEDULED, label: 'Rescheduled' },
    { value: schedulesConstants_1.SCHEDULE_STATUS_RECYCLE, label: 'Recycle' },
];
exports.ORDER_BY_CHOICES = [
    { value: '', label: 'Sort by' },
    { value: 'call_count', label: 'Call Count (Ascending)' },
    { value: '-call_count', label: 'Call Count (Descending)' },
    { value: 'last_call_date', label: 'Last Call Date (Ascending)' },
    { value: '-last_call_date', label: 'Last Call Date (Descending)' },
];
