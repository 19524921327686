"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetActionState = exports.resetSendSMSState = exports.resetLogCallState = exports.redFlag = exports.escalateRecovery = exports.flagAsDoNotCall = exports.deleteInstalment = exports.customNotes = exports.notifyDroppedCall = exports.logCall = exports.customAction = exports.sendSMS = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const constants_1 = require("../../constants/constants");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const scheduleSlice_1 = require("../schedules/scheduleSlice");
const actionsConstants_1 = require("./actionsConstants");
const baseState = {
    status: typesConstants_1.IDLE_STATUS,
    errorMessage: null,
    errors: undefined,
};
const initialState = {
    sendSMS: { ...baseState },
    customAction: { ...baseState },
    logCall: { ...baseState },
    deleteInstalment: { ...baseState },
    flagAsDoNotCall: { ...baseState },
    customNotes: { ...baseState },
    escalateRecovery: { ...baseState },
    redFlag: { ...baseState },
    droppedCall: { ...baseState },
};
exports.sendSMS = toolkit_1.createAsyncThunk('actions/send/SMS', async ({ scheduleId, content, recipient_phone_number }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.SEND_SMS_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = { content, recipient_phone_number };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.customAction = toolkit_1.createAsyncThunk('actions/custom', async ({ scheduleId, action_key, metadata, action_trigger_url }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.CUSTOM_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = { action_key, metadata, action_trigger_url };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.logCall = toolkit_1.createAsyncThunk('actions/log/call', async ({ scheduleId, recipient_name, recipient_phone_number, call_type, call_status, duration, notes, ptp_date, pttl_date, provider, sip_data, payment_delay_reasons, payment_delay_reason_categories, escalation_reasons, escalation_reason_categories, }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.LOG_CALL_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = {
            recipient_name,
            recipient_phone_number,
            call_type,
            call_status,
            duration,
            ptp_date,
            pttl_date,
            notes,
            ...(sip_data && { sip_data }),
            ...((provider || provider === 0) && { provider }),
            ...(payment_delay_reasons &&
                payment_delay_reasons.length > 0 && { payment_delay_reasons, payment_delay_reason_categories }),
            ...(escalation_reasons &&
                escalation_reasons.length > 0 && { escalation_reasons, escalation_reason_categories }),
        };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.notifyDroppedCall = toolkit_1.createAsyncThunk('actions/log/droppedCall', async ({ scheduleId, recipient_name, recipient_phone_number, duration }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.DROPPED_CALL_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = {
            recipient_name,
            recipient_phone_number,
            duration,
        };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.customNotes = toolkit_1.createAsyncThunk('home/customNote', async ({ notes, scheduleId }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.ADD_CUSTOM_NOTES_API_PATH.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const body = {
            notes,
        };
        const { auth } = getState();
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.deleteInstalment = toolkit_1.createAsyncThunk('actions/delete/instalment', async ({ scheduleId, instalment_id }, { getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.DELETE_INSTALMENT_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = { instalment_id };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.flagAsDoNotCall = toolkit_1.createAsyncThunk('actions/flag/do_not_call', async ({ scheduleId, selectedContact }, { dispatch, getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.FLAG_AS_DO_NOT_CALL_ACTION_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = {
            recipient_name: `${selectedContact.first_name} ${selectedContact.last_name}`,
            recipient_phone_number: selectedContact.phone_number,
            flagged: !selectedContact.do_not_call,
            relationship: selectedContact.relationship ? selectedContact.relationship : '',
        };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON)
            .then((result) => {
            dispatch(scheduleSlice_1.toggleSelectedContactDoNotCall(selectedContact));
            return result;
        });
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.escalateRecovery = toolkit_1.createAsyncThunk('actions/escalate/recovery', async ({ scheduleId, value }, { dispatch, getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.ESCALATE_TO_RECOVERY_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = {
            escalated_to_field_recovery: value,
        };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON)
            .then((result) => {
            dispatch(scheduleSlice_1.toggleEscalateToFieldRecovery());
            return result;
        });
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
exports.redFlag = toolkit_1.createAsyncThunk('actions/red/flag', async ({ scheduleId, value }, { dispatch, getState, rejectWithValue }) => {
    try {
        const url = actionsConstants_1.RED_FLAG_URL.replace(constants_1.SCHEDULE_ID_TEMPLATE_TAG, scheduleId);
        const { auth } = getState();
        const body = {
            red_flag: value,
        };
        return await network_1.post(url, auth.token, body)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON)
            .then((result) => {
            dispatch(scheduleSlice_1.toggleRedFlag());
            return result;
        });
    }
    catch (err) {
        if (err.message === constants_1.DEFAULT_NETWORK_ERROR_MESSAGE) {
            react_native_1.Alert.alert('Network Error', 'Unable to connect to server.\nPlease check your internet connection.');
            throw new Error(err);
        }
        return rejectWithValue(err);
    }
});
const actionsSlice = toolkit_1.createSlice({
    name: 'actions',
    initialState,
    reducers: {
        resetLogCallState(state) {
            return {
                ...state,
                logCall: {
                    status: typesConstants_1.IDLE_STATUS,
                    errorMessage: null,
                },
            };
        },
        resetActionState() {
            return initialState;
        },
        resetSendSMSState(state) {
            return {
                ...state,
                sendSMS: {
                    status: typesConstants_1.IDLE_STATUS,
                    errorMessage: null,
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.sendSMS.pending, (state) => ({
            ...state,
            sendSMS: {
                status: typesConstants_1.PENDING_STATUS,
                errorMessage: null,
            },
        }))
            .addCase(exports.sendSMS.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.sendSMS = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.sendSMS.fulfilled, (state) => ({
            ...state,
            sendSMS: {
                status: typesConstants_1.SUCCEEDED_STATUS,
                errorMessage: null,
            },
        }))
            .addCase(exports.customAction.pending, (state) => ({
            ...state,
            customAction: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.customAction.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.customAction = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.customAction.fulfilled, (state) => ({
            ...state,
            customAction: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.logCall.pending, (state) => ({
            ...state,
            logCall: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.logCall.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.logCall = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.logCall.fulfilled, (state) => ({
            ...state,
            logCall: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.deleteInstalment.pending, (state) => ({
            ...state,
            deleteInstalment: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.deleteInstalment.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.deleteInstalment = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.deleteInstalment.fulfilled, (state) => ({
            ...state,
            deleteInstalment: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.flagAsDoNotCall.pending, (state) => ({
            ...state,
            flagAsDoNotCall: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.flagAsDoNotCall.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.flagAsDoNotCall = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.flagAsDoNotCall.fulfilled, (state) => ({
            ...state,
            flagAsDoNotCall: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.customNotes.pending, (state) => ({
            ...state,
            customNotes: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.customNotes.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.customNotes = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.customNotes.fulfilled, (state) => ({
            ...state,
            customNotes: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.escalateRecovery.pending, (state) => ({
            ...state,
            escalateRecovery: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.escalateRecovery.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.escalateRecovery = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.escalateRecovery.fulfilled, (state) => ({
            ...state,
            escalateRecovery: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.redFlag.pending, (state) => ({
            ...state,
            redFlag: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.redFlag.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.redFlag = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.redFlag.fulfilled, (state) => ({
            ...state,
            redFlag: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }))
            .addCase(exports.notifyDroppedCall.pending, (state) => ({
            ...state,
            droppedCall: { status: typesConstants_1.PENDING_STATUS, errorMessage: null },
        }))
            .addCase(exports.notifyDroppedCall.rejected, (state, { payload }) => {
            const newState = { ...state };
            newState.droppedCall = {
                status: typesConstants_1.FAILED_STATUS,
                errorMessage: payload.message,
                errors: payload.errorObject,
            };
            return newState;
        })
            .addCase(exports.notifyDroppedCall.fulfilled, (state) => ({
            ...state,
            notifyDroppedCall: { status: typesConstants_1.SUCCEEDED_STATUS, errorMessage: null },
        }));
    },
});
_a = actionsSlice.actions, exports.resetLogCallState = _a.resetLogCallState, exports.resetSendSMSState = _a.resetSendSMSState, exports.resetActionState = _a.resetActionState;
exports.default = actionsSlice.reducer;
