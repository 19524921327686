"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("../../web/config");
const screenConstants_1 = require("../constants/screenConstants");
const LinkingMain = {
    prefixes: config_1.LINKING_PREFIXES,
    config: {
        screens: {
            [screenConstants_1.SIGNIN_SCREEN]: '/login',
            [screenConstants_1.SCHEDULE_SCREEN]: '/schedule',
            [screenConstants_1.SEARCH_SCREEN]: '/search',
            [screenConstants_1.PROFILE_SCREEN]: '/profile',
            [screenConstants_1.PREVIOUS_SCHEDULES_SCREEN]: '/previous-schedules',
            [screenConstants_1.AUDITED_CDR_SCREEN]: '/audited-call-detail-record',
            [screenConstants_1.OFFICER_SCHEDULES_SCREEN]: '/officers-schedules',
            [screenConstants_1.OFFICERS_METRICS_SCREEN]: '/officers-metrics',
            [screenConstants_1.DASHBOARD_SCREEN]: '/',
            [screenConstants_1.QUALITY_ASSURANCE_SCREEN]: '/quality-assurance',
            [screenConstants_1.AUDIT_SCREEN]: '/audit',
            [screenConstants_1.TICKET_SCREEN]: '/ticket',
            [screenConstants_1.TICKET_DETAIL_SCREEN]: '/ticket-detail',
            [screenConstants_1.CAMPAIGN_SCREEN]: '/campaign',
            [screenConstants_1.CAMPAIGNS_SCREEN]: '/campaigns',
            [screenConstants_1.CAMPAIGNS_METRICS_SCREEN]: '/campaign-metrics',
            [screenConstants_1.NOTIFICATIONS_SCREEN]: '/notifications',
            [screenConstants_1.MANUAL_CONFIRMATION_SCREEN]: '/manual-confirmation-schedules',
            [screenConstants_1.TOP_USER_ENGAGEMENT_SCREEN]: '/top-user-engagement-schedules',
        },
    },
};
exports.default = LinkingMain;
