"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resetSchedulesState = exports.getPreviousSchedules = exports.getTopUserEngagementSchedules = exports.getManualConfirmationSchedules = exports.getOfficerSchedules = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const typesConstants_1 = require("../../constants/typesConstants");
const network_1 = require("../../utils/network");
const utils_1 = require("../../utils/utils");
const schedulesConstants_1 = require("./schedulesConstants");
exports.getOfficerSchedules = toolkit_1.createAsyncThunk('schedules/getOfficerSchedules', async ({ officerId, page }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const today = new Date().toISOString().split('T')[0];
        const queryParams = utils_1.encodeUrlParams({
            datetime_created__date: today,
            status: schedulesConstants_1.ACTIVE_SCHEDULE_STATUSES,
            credit_officer: officerId,
            ...(page && { page: String(page) }),
        });
        const urlPath = `${schedulesConstants_1.SCHEDULES_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getManualConfirmationSchedules = toolkit_1.createAsyncThunk('schedules/getManualConfirmationSchedules', async ({ officerId, status, page }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const today = new Date().toISOString().split('T')[0];
        const queryParams = utils_1.encodeUrlParams({
            datetime_created__date: today,
            category: typesConstants_1.SCHEDULE_CATEGORY_MANUAL_CONFIRMATION.toString(),
            ...(status !== undefined ? { status: status.toString() } : { status: schedulesConstants_1.NOT_COMPLETED_STATUSES }),
            ...(page && { page: String(page) }),
            ...(officerId && { credit_officer: officerId }),
        });
        const urlPath = `${schedulesConstants_1.SCHEDULES_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getTopUserEngagementSchedules = toolkit_1.createAsyncThunk('schedules/getTopUserEngagementSchedules', async ({ officer_id, status, min_call_count, max_call_count, call_date_after, call_date_before, order_by, external_id, page, }, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const queryParams = utils_1.encodeUrlParams({
            category: typesConstants_1.SCHEDULE_CATEGORY_TOP_USER_ENGAGEMENT.toString(),
            ...(status !== undefined && status !== '' && { status: status.toString() }),
            ...(page && { page: String(page) }),
            ...(officer_id && { credit_officer: officer_id }),
            ...(min_call_count && { min_call_count }),
            ...(max_call_count && { max_call_count }),
            ...(call_date_after && { call_date_after }),
            ...(call_date_before && { call_date_before }),
            ...(order_by && { order_by: order_by.toString() }),
            ...(external_id && { external_id }),
        });
        const urlPath = `${schedulesConstants_1.SCHEDULES_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
exports.getPreviousSchedules = toolkit_1.createAsyncThunk('schedules/getPreviousSchedules', async (page, { getState, rejectWithValue }) => {
    try {
        const { auth } = getState();
        const today = new Date().toISOString().split('T')[0];
        const previousSchedulePathParams = {
            status: String(schedulesConstants_1.SCHEDULE_STATUS_COMPLETED),
            datetime_created__date: today,
            credit_officer: auth.user.id,
            ...(page && { page: String(page) }),
        };
        const queryParams = utils_1.encodeUrlParams(previousSchedulePathParams);
        const urlPath = `${schedulesConstants_1.SCHEDULES_API_PATH}?${queryParams}`;
        return await network_1.get(urlPath, auth.token)
            .then(network_1.checkHttpStatus)
            .then(network_1.parseJSON);
    }
    catch (err) {
        return network_1.handleDefaultError(err, rejectWithValue);
    }
});
const initialState = {
    schedules: [],
    loadingStatus: typesConstants_1.PENDING_STATUS,
    currentPage: null,
    nextPage: null,
    previousPage: null,
    totalPages: null,
    totalEntries: null,
    pageSize: 10,
};
const schedulesSlice = toolkit_1.createSlice({
    name: 'schedules',
    initialState,
    reducers: {
        resetSchedulesState() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.getOfficerSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getOfficerSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getOfficerSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }))
            .addCase(exports.getPreviousSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getPreviousSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getPreviousSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }))
            .addCase(exports.getManualConfirmationSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getManualConfirmationSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getManualConfirmationSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }))
            .addCase(exports.getTopUserEngagementSchedules.pending, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.PENDING_STATUS,
        }))
            .addCase(exports.getTopUserEngagementSchedules.fulfilled, (state, { payload }) => ({
            ...state,
            loadingStatus: typesConstants_1.SUCCEEDED_STATUS,
            schedules: payload.results,
            currentPage: payload.current,
            nextPage: payload.next,
            previousPage: payload.previous,
            totalPages: payload.total_pages,
            totalEntries: payload.total_entries,
            pageSize: payload.page_size,
        }))
            .addCase(exports.getTopUserEngagementSchedules.rejected, (state) => ({
            ...state,
            loadingStatus: typesConstants_1.FAILED_STATUS,
        }));
    },
});
exports.resetSchedulesState = schedulesSlice.actions.resetSchedulesState;
exports.default = schedulesSlice.reducer;
